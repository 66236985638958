
<script>
export default {
  methods: {
    detailValidation(p) {

      if (this.resp && this.resp.Parts != undefined) {
        let item = this.resp.Parts.find(part => part.No == this.detail.no || part.No == this.detail.No),
            response = item != undefined && item[p] != undefined ? false : null;
        return response;
      }
      return null;
    },
    fixedLength(detail, key) {
      detail[key] = detail[key].replace(/[^0-9\.\,]+/g, '') == '' ? '' : parseFloat(detail[key]).toFixed(1)
    },
    detailValidationText(p) {
      if (this.resp && this.resp.Parts != undefined) {

        let item = this.resp.Parts.find(part => part.No == this.detail.no || part.No == this.detail.No),
            response = item != undefined && item[p] != undefined ? 'Вірно: ' + item[p] : '';
        if (item && item[p] === 0) {
          if (p.includes('Edge')) {
            response = 'Вірно: Ні';
          }
          if (p.includes('rotate') || p.includes('screed_bp')) {
            response = 'Вірно: Викл(0)';
          }
        }
        return response;
      }
      return '';
    },
  },
};
</script>
